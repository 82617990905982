/**************
** HOME PAGE **
***************/

#home-hero{
  padding-top: 14em;
  padding-bottom: 12em;
  margin-right: 1em;
  background: url("./images/chat_confused.png") no-repeat bottom right;
  background-size: 500px, auto, contain;
  color: #232323;
}

#home-content{
  padding-bottom: 40px;
}

#main-focus{
  color: #091C60;
  background-color: #FEFCF9;
  -webkit-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  border-radius: 10px;
  padding: 16px 32px 16px 32px;
  margin-top: -50px;
  margin-bottom: 70px;
  font-size: 1.1em;
}

.key-sentences{
  background-color: #F4F2ED;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  height: 100%;
  min-height: 270px;
}

.key-sentences h2{
  width: 78%;
  line-height: 1.2em;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#sources p{
  font-size: 16px;
  padding: 0;
  margin: 0;
}

#sources a{
  color: #A4A4A4;
}

@media only screen and (max-width: 960px) {

  #home-hero{
    padding-top: 6em;
    margin-right: 1em;
    background: url("./images/chat_confused.png") no-repeat bottom center;
    background-size: 350px, auto, contain;;
    padding-bottom: 22em;
    text-align: center;
  }

  #home-hero #subtitle{
    max-width: 100%;
  }


  #main-focus{
    margin-top: -30px;
  }

  #sources{
    margin-top: 32px;
  }

  .key-sentences{
    width: 100%;
    display: inline-flex;
    min-height: 0;
  }

  .key-sentences h2{
    padding: 40px;
    width: 100%;
    line-height: 1.2em;
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}
