/***********************************************************
** DOCUMENTS PAGES (User Manual / T&Cs / Privacy Policy) **
***********************************************************/

.documents-container{
    padding-top: 2em;
    padding-bottom: 6em;
}

.documents-hero{
    padding-top: 10em;
    padding-bottom: 3em;
    color: #232323;
}

.documents-hero hr{
    opacity: 0.5;
    margin: 20px 0 20px 0;
}

.documents-container h2{
    margin-top: 32px;
}

.documents-container h4{
    font-size: 1.3em;
}

.documents-container a{
    font-weight: 700;
}

.anchor{
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
}

#table-container{
    overflow-x: scroll;
}

tr{
    border-bottom: 1px solid #EBEBEB;
}

table{
    margin-top: 15px;
    width: 100%;
    table-layout: fixed ;
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    min-width: 700px;
    border-collapse: collapse;
}

th{
    background-color: #F6F6F6;
    text-align: center;
    padding: 12px 0;
}

td{
    padding: 15px 10px;
}

table.change-history{
    min-width: 200px;
    width: 100%;
}

.change-history th{
    text-align: left;
    padding-left: 10px;
}

@media only screen and (max-width: 960px) {
    .documents-container{
        padding-top: 1em;
    }
    .documents-hero{
        padding-top: 7em;
        padding-bottom: 3em;
      }
}
