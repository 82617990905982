@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
body, html {
  margin: 0;
  font-family: 'Inter-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  overflow-x:hidden;

}

h1{
  font-size: 5em;
  font-family: 'Inter-Bold', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

h2{
  font-family: 'Inter-Bold', sans-serif;
  font-size: 2.4em;
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

h3{
  font-size: 1.8em;
  font-family: 'Inter-Bold', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h4{
  font-size: 20px;
  font-family: 'Inter-Bold', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h5{
  font-size: 1em;
  font-weight: 700;
  margin: 0 0 22px 0;
  padding: 0;
  line-height: 1.4em;
}

p{
  font-size: 20px;
  line-height: 30px;
}

b{
  font-family: 'Inter-Bold', sans-serif;
}

ul, ol{
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

li{
  margin-bottom: 8px;
}

ol{
  padding-left: 18px;
}

hr{
  margin: 48px 0px 48px 0px;
  border-bottom: 0;
  border-top: 1px solid #DBDBDB;
}

a{
  text-decoration: none;
  color: #091C60;
}

.switch-wrapper {
  width: 100%;
  position: absolute;
}

.nomargin {
  margin: 0;
}

.margin48 {
  margin: 48px 0 48px 0;
}

.ppinkdarker-bkg{
  background-color: #E07979;
}

.opacity{
  opacity: 0.5;
}

.button{
  background-color: #ffffff!important;
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
  height: auto!important;
  border-radius: 16px!important;
  color: #091C60!important;
  font-family: 'Inter-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif!important;
  background: url(/static/media/arrow-next.8b096d64.svg) no-repeat 96% 50%;
  padding: 20px 0px 20px 5%!important;
  text-align: left!important;
  justify-content: left!important;
  transition: all 0.2s ease-in-out!important;
  text-transform: initial!important ;
  font-size: 1.1em!important;
  display: inline-table!important;
}

.button:hover {
  background: url(/static/media/arrow-next.8b096d64.svg) no-repeat 95% 50%!important;
  background-color: #F6F6F6;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08)!important;
  transition: all 0.2s ease-in-out!important;
}


.profile-pic{
  border-radius: 16px;
  padding: 0;
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  background-repeat: no-repeat;
  background-size: 220px;
  background-position: bottom right;
  height: 170px;
  width: 100%;
  background-color: #ffffff;
}

.MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0.04)!important;
}

.MuiFilledInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2)!important;
}

.MuiFormLabel-root{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif!important;
  font-weight: 500!important;
}

.MuiFilledInput-underline:after {
    border-bottom: 2px solid #581357!important;
}

.MuiFormLabel-root.Mui-focused{
  color: #581357!important;
}

.MuiFilledInput-input{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif!important;
  font-weight: 600!important;
  color: #581357!important;
}

@media only screen and (max-width: 960px) {
  h1{
    font-size: 3.2em;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1.1em;
  }

  h2{
    font-size: 1.7em;
    letter-spacing: -0.01em;
  }

  .nomargin {
    margin-top: 30px;
  }

  .margin48 {
    margin: 0;
  }

  .MuiContainer-root{
    padding-left: 24px!important;
    padding-right: 24px!important;
  }

}

@media only screen and (max-width: 600px) {

  .profile-pic{
    background-size: 230px;
  }

  p{
    font-size: 20px;
    line-height: 30px;
  }

}

/**************
** HOME PAGE **
***************/

#home-hero{
  padding-top: 14em;
  padding-bottom: 12em;
  margin-right: 1em;
  background: url(/static/media/chat_confused.da628627.png) no-repeat bottom right;
  background-size: 500px, auto, contain;
  color: #232323;
}

#home-content{
  padding-bottom: 40px;
}

#main-focus{
  color: #091C60;
  background-color: #FEFCF9;
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  border-radius: 10px;
  padding: 16px 32px 16px 32px;
  margin-top: -50px;
  margin-bottom: 70px;
  font-size: 1.1em;
}

.key-sentences{
  background-color: #F4F2ED;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  height: 100%;
  min-height: 270px;
}

.key-sentences h2{
  width: 78%;
  line-height: 1.2em;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#sources p{
  font-size: 16px;
  padding: 0;
  margin: 0;
}

#sources a{
  color: #A4A4A4;
}

@media only screen and (max-width: 960px) {

  #home-hero{
    padding-top: 6em;
    margin-right: 1em;
    background: url(/static/media/chat_confused.da628627.png) no-repeat bottom center;
    background-size: 350px, auto, contain;;
    padding-bottom: 22em;
    text-align: center;
  }

  #home-hero #subtitle{
    max-width: 100%;
  }


  #main-focus{
    margin-top: -30px;
  }

  #sources{
    margin-top: 32px;
  }

  .key-sentences{
    width: 100%;
    display: inline-flex;
    min-height: 0;
  }

  .key-sentences h2{
    padding: 40px;
    width: 100%;
    line-height: 1.2em;
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

}

/***********
** FOOTER **
************/

.footer{
    padding-bottom: 30px;
    text-align: left;
}

#footer-container{
    padding: 30px 0 30px 0;
    border-radius: 16px;
    margin-bottom: 30px;
}

.footer svg{
    fill: #091C60;
    width: 100px;
    height: 45px;
    padding: 5px 40px 40px 40px;
}

.footer ul{
    list-style-type: none;
    margin: 0;
    padding: 6px 0 0 0;
    font-weight: 400;
    font-size: 1.1em;
}

.footer li{
    padding-bottom: 2px;
    transition: all 0.3s ease-in-out;
}

.footer li a:hover{
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}

.footer .selected:before {
    content: "•";
    font-size: 32px;
    line-height: 0px;
    position: relative;
    left: -8px;
    bottom: -6px;
    height: 0;
    width: 0;
    margin-left: -10px;
}

.footer #copyright{
    font-weight: 500;
    font-size: 0.9em;
    color: #b9b9b9;
    text-align: center;
}

@media only screen and (max-width: 960px) {

    .footer{
        text-align: center;
    }

    .footer svg {
        padding: 5px 40px 10px 40px;
    }
}

/**************
** 404 PAGE **
***************/

#errorpage-container{
    padding-top: 2em;
    padding-bottom: 6em;
}

#errorpage-hero{
    padding-top: 10em;
    padding-bottom: 3em;
    color: #FEFCF9;
}

@media only screen and (max-width: 960px) {
  
    #errorpage-hero{
      text-align: center;
      padding-top: 7em;
      padding-bottom: 3em;
    }

    #errorpage-container{
        text-align: center;
    }
}
/***********************************************************
** DOCUMENTS PAGES (User Manual / T&Cs / Privacy Policy) **
***********************************************************/

.documents-container{
    padding-top: 2em;
    padding-bottom: 6em;
}

.documents-hero{
    padding-top: 10em;
    padding-bottom: 3em;
    color: #232323;
}

.documents-hero hr{
    opacity: 0.5;
    margin: 20px 0 20px 0;
}

.documents-container h2{
    margin-top: 32px;
}

.documents-container h4{
    font-size: 1.3em;
}

.documents-container a{
    font-weight: 700;
}

.anchor{
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
}

#table-container{
    overflow-x: scroll;
}

tr{
    border-bottom: 1px solid #EBEBEB;
}

table{
    margin-top: 15px;
    width: 100%;
    table-layout: fixed ;
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    min-width: 700px;
    border-collapse: collapse;
}

th{
    background-color: #F6F6F6;
    text-align: center;
    padding: 12px 0;
}

td{
    padding: 15px 10px;
}

table.change-history{
    min-width: 200px;
    width: 100%;
}

.change-history th{
    text-align: left;
    padding-left: 10px;
}

@media only screen and (max-width: 960px) {
    .documents-container{
        padding-top: 1em;
    }
    .documents-hero{
        padding-top: 7em;
        padding-bottom: 3em;
      }
}

/***********
** NAVBAR **
************/

.navbar {
    padding: 40px 0 10px 0;
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: all 0.3s ease-in-out;
}

.navbar svg{
    width: 100%;
    min-width: 102px;
    height: 100%;
    fill: #FEFCF9;
    transition: all 0.3s ease-in-out;
}

.navbar #nav-items{
    text-align: center;
    padding-top: 18px;
}

.navbar #nav-items a{
    text-decoration: none;
    color: #3F444D;
    font-weight: 400;
    font-size: 16px;
    opacity: 0.8;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 6px 20px 6px 20px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 32px;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

.navbar #nav-items a:hover{
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.navbar #nav-items a.selected {
    box-sizing: border-box;
    opacity: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.158);
}

.navbar.active .selected {
    transition: all 0.3s ease-in-out;
}

.navbar.active{
    padding: 0px 0 0px 0;
    background: white;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
}

.navbar.active #nav-items a{
    color: #091C60;
    transition: all 0.3s ease-in-out;
}

.navbar.active #nav-items a.selected {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid rgba(9, 28, 96, 0.158);
}

.navbar.active #nav-items{
    padding-top: 28px;
    transition: all 0.3s ease-in-out;
}

.navbar.active svg{
    margin: 18px 0px 15px 0px;
    padding: 0px;
    width: 80px;
    height: unset;
    min-width: 80px;
    fill: #091C60;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile{
    display: none;
    background-color: #ffffff;
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    height: 54px;
    text-align: center;
}

.navbar-mobile svg{
    width: 64px;
    height: 38px;
    margin-top: 8px;
    fill: #091C60;
}

.navbar-mobile-toggle{
    height: 38px;
    width: 38px;
    cursor: pointer;
    position: absolute;
    margin-left: 16px;
    margin-top: 8px;
    background: url(/static/media/ic_menu.347f6572.svg) no-repeat center center;
    fill: #091C60;
    background-size: 24px;
    transition: all 0.2s ease-in-out;
}

.navbar-mobile-toggle.active{
    background: url(/static/media/ic_close.3db605d4.svg) no-repeat center center;
    transition: all 0.2s ease-in-out;
}

.navbar-mobile-menu{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0;
    height: 100%;
    position: fixed;
    text-align: left;
    z-index: 0;
    top: 54px;
    border-top: 1px solid #DBDBDB;
    margin-left: -100%;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile-menu.active{
    margin-left: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile-menu a{
    display: block;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 20px 24px 10%;
    position: relative;
    transition: all 0.5s ease-in;
}

.navbar-mobile-menu a:after {
    content : "";
    position: absolute;
    left    : 10%;
    bottom  : 4px;
    height  : 1px;
    width   : 80%;
    border-bottom:1px solid #DBDBDB;
    transition: all 0.3s ease-in-out;
}


@media only screen and (max-width: 960px) {

    .navbar{
        display: none;
    }

    .navbar-mobile{
        display: inline;
    }
}

