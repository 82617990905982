/**************
** 404 PAGE **
***************/

#errorpage-container{
    padding-top: 2em;
    padding-bottom: 6em;
}

#errorpage-hero{
    padding-top: 10em;
    padding-bottom: 3em;
    color: #FEFCF9;
}

@media only screen and (max-width: 960px) {
  
    #errorpage-hero{
      text-align: center;
      padding-top: 7em;
      padding-bottom: 3em;
    }

    #errorpage-container{
        text-align: center;
    }
}