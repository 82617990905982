/***********
** NAVBAR **
************/

.navbar {
    padding: 40px 0 10px 0;
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: all 0.3s ease-in-out;
}

.navbar svg{
    width: 100%;
    min-width: 102px;
    height: 100%;
    fill: #FEFCF9;
    transition: all 0.3s ease-in-out;
}

.navbar #nav-items{
    text-align: center;
    padding-top: 18px;
}

.navbar #nav-items a{
    text-decoration: none;
    color: #3F444D;
    font-weight: 400;
    font-size: 16px;
    opacity: 0.8;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 6px 20px 6px 20px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 32px;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

.navbar #nav-items a:hover{
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.navbar #nav-items a.selected {
    box-sizing: border-box;
    opacity: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.158);
}

.navbar.active .selected {
    transition: all 0.3s ease-in-out;
}

.navbar.active{
    padding: 0px 0 0px 0;
    background: white;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
}

.navbar.active #nav-items a{
    color: #091C60;
    transition: all 0.3s ease-in-out;
}

.navbar.active #nav-items a.selected {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid rgba(9, 28, 96, 0.158);
}

.navbar.active #nav-items{
    padding-top: 28px;
    transition: all 0.3s ease-in-out;
}

.navbar.active svg{
    margin: 18px 0px 15px 0px;
    padding: 0px;
    width: 80px;
    height: unset;
    min-width: 80px;
    fill: #091C60;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile{
    display: none;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.15);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    height: 54px;
    text-align: center;
}

.navbar-mobile svg{
    width: 64px;
    height: 38px;
    margin-top: 8px;
    fill: #091C60;
}

.navbar-mobile-toggle{
    height: 38px;
    width: 38px;
    cursor: pointer;
    position: absolute;
    margin-left: 16px;
    margin-top: 8px;
    background: url("./images/ic_menu.svg") no-repeat center center;
    fill: #091C60;
    background-size: 24px;
    transition: all 0.2s ease-in-out;
}

.navbar-mobile-toggle.active{
    background: url("./images/ic_close.svg") no-repeat center center;
    transition: all 0.2s ease-in-out;
}

.navbar-mobile-menu{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0;
    height: 100%;
    position: fixed;
    text-align: left;
    z-index: 0;
    top: 54px;
    border-top: 1px solid #DBDBDB;
    margin-left: -100%;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile-menu.active{
    margin-left: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.navbar-mobile-menu a{
    display: block;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 20px 24px 10%;
    position: relative;
    transition: all 0.5s ease-in;
}

.navbar-mobile-menu a:after {
    content : "";
    position: absolute;
    left    : 10%;
    bottom  : 4px;
    height  : 1px;
    width   : 80%;
    border-bottom:1px solid #DBDBDB;
    transition: all 0.3s ease-in-out;
}


@media only screen and (max-width: 960px) {

    .navbar{
        display: none;
    }

    .navbar-mobile{
        display: inline;
    }
}
