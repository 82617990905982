/***********
** FOOTER **
************/

.footer{
    padding-bottom: 30px;
    text-align: left;
}

#footer-container{
    padding: 30px 0 30px 0;
    border-radius: 16px;
    margin-bottom: 30px;
}

.footer svg{
    fill: #091C60;
    width: 100px;
    height: 45px;
    padding: 5px 40px 40px 40px;
}

.footer ul{
    list-style-type: none;
    margin: 0;
    padding: 6px 0 0 0;
    font-weight: 400;
    font-size: 1.1em;
}

.footer li{
    padding-bottom: 2px;
    transition: all 0.3s ease-in-out;
}

.footer li a:hover{
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}

.footer .selected:before {
    content: "•";
    font-size: 32px;
    line-height: 0px;
    position: relative;
    left: -8px;
    bottom: -6px;
    height: 0;
    width: 0;
    margin-left: -10px;
}

.footer #copyright{
    font-weight: 500;
    font-size: 0.9em;
    color: #b9b9b9;
    text-align: center;
}

@media only screen and (max-width: 960px) {

    .footer{
        text-align: center;
    }

    .footer svg {
        padding: 5px 40px 10px 40px;
    }
}
