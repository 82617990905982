@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body, html {
  margin: 0;
  font-family: 'Inter-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  overflow-x:hidden;

}

h1{
  font-size: 5em;
  font-family: 'Inter-Bold', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

h2{
  font-family: 'Inter-Bold', sans-serif;
  font-size: 2.4em;
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

h3{
  font-size: 1.8em;
  font-family: 'Inter-Bold', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h4{
  font-size: 20px;
  font-family: 'Inter-Bold', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h5{
  font-size: 1em;
  font-weight: 700;
  margin: 0 0 22px 0;
  padding: 0;
  line-height: 1.4em;
}

p{
  font-size: 20px;
  line-height: 30px;
}

b{
  font-family: 'Inter-Bold', sans-serif;
}

ul, ol{
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

li{
  margin-bottom: 8px;
}

ol{
  padding-left: 18px;
}

hr{
  margin: 48px 0px 48px 0px;
  border-bottom: 0;
  border-top: 1px solid #DBDBDB;
}

a{
  text-decoration: none;
  color: #091C60;
}

.switch-wrapper {
  width: 100%;
  position: absolute;
}

.nomargin {
  margin: 0;
}

.margin48 {
  margin: 48px 0 48px 0;
}

.ppinkdarker-bkg{
  background-color: #E07979;
}

.opacity{
  opacity: 0.5;
}

.button{
  background-color: #ffffff!important;
  -webkit-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
  -moz-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1)!important;
  height: auto!important;
  border-radius: 16px!important;
  color: #091C60!important;
  font-family: 'Inter-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif!important;
  background: url("../components/SharedImages/arrow-next.svg") no-repeat 96% 50%;
  padding: 20px 0px 20px 5%!important;
  text-align: left!important;
  justify-content: left!important;
  transition: all 0.2s ease-in-out!important;
  text-transform: initial!important ;
  font-size: 1.1em!important;
  display: inline-table!important;
}

.button:hover {
  background: url("../components/SharedImages/arrow-next.svg") no-repeat 95% 50%!important;
  background-color: #F6F6F6;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08)!important;
  -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08)!important;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08)!important;
  transition: all 0.2s ease-in-out!important;
}


.profile-pic{
  border-radius: 16px;
  padding: 0;
  -webkit-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 17px 46px 0px rgba(0,0,0,0.1);
  background-repeat: no-repeat;
  background-size: 220px;
  background-position: bottom right;
  height: 170px;
  width: 100%;
  background-color: #ffffff;
}

.MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0.04)!important;
}

.MuiFilledInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2)!important;
}

.MuiFormLabel-root{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif!important;
  font-weight: 500!important;
}

.MuiFilledInput-underline:after {
    border-bottom: 2px solid #581357!important;
}

.MuiFormLabel-root.Mui-focused{
  color: #581357!important;
}

.MuiFilledInput-input{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif!important;
  font-weight: 600!important;
  color: #581357!important;
}

@media only screen and (max-width: 960px) {
  h1{
    font-size: 3.2em;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1.1em;
  }

  h2{
    font-size: 1.7em;
    letter-spacing: -0.01em;
  }

  .nomargin {
    margin-top: 30px;
  }

  .margin48 {
    margin: 0;
  }

  .MuiContainer-root{
    padding-left: 24px!important;
    padding-right: 24px!important;
  }

}

@media only screen and (max-width: 600px) {

  .profile-pic{
    background-size: 230px;
  }

  p{
    font-size: 20px;
    line-height: 30px;
  }

}
